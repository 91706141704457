import React from 'react'
/*eslint-disable-next-line no-unused-vars */
import { BrowserRouter, Route } from 'react-router-dom'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Home from './components/Home'
import Navbar from './components/Navbar'
import Portfolio from './components/Portfolio'
import 'animate.css'

const App = () => {



  return (
    <BrowserRouter>
      <Navbar />
      {/* <Route exact path='/' component={Home} />
      <Route exact path='/about' component={About} /> */}
      <Home />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </BrowserRouter>
  )
}

export default App