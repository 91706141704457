
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider, Image, Dot } from 'pure-react-carousel'
import React from 'react'
import { Container, Header, Segment, Image as SImage, Button, Card, Modal, Icon, Grid } from 'semantic-ui-react'
import 'pure-react-carousel/dist/react-carousel.es.css'
import {
  BootstrapIcon, BulmaIcon, cssIcon,
  DjangoIcon, expressIcon, figmaIcon, GitHubIcon, GitIcon,
  HerokuIcon, HtmlIcon, JavaScriptIcon,
  MongoIcon, NodeIcon, poetryIcon, PostgresIcon,
  PythonIcon, ReactIcon, slackIcon, YarnIcon
} from './helpers/Icons'



const Portfolio = () => {


  const iconSize = 60

  const projects = [
    {
      name: 'Hotline Pac-man',
      image: 'https://res.cloudinary.com/dkvkg5pxm/image/upload/c_scale,w_800/v1641401979/finalspace_gudrkz.webp',
      description: 'Browser implemenation of Pacman inspired by Dennaton Games\'s top-down shooter', url: 'https://rjriverac.github.io/SEI-project-1/',
      process: <Container text>
        <Header as='h4' size='medium' inverted>Brief </Header>
        <p>My first project with General Assembly, the aim was to build a browser version of Pac-Man using vanilla javascript and HTML over the course of a week</p>
        <Header as='h4' size='medium' inverted>Result</Header>

        <p>The game itself is quite difficult, as my behavior for the ghosts spends most of the game calculating the shortest path to the player and moving to intercept!

          A great learning experience after a brief introduction to JavaScript.
        </p>
        <Header as='h5' size='small' inverted>Built using:</Header>
        {
          <Grid centered stackable doubling>
            <Grid.Row stretched columns='equal' verticalAlign='top'>
              {[JavaScriptIcon, HtmlIcon, cssIcon, GitHubIcon, GitIcon].map((item, ind) => {
                const title = (ind === 3 || ind === 0) ? true : false
                let caption
                switch (ind) {
                  case 3:
                    caption = 'GitHub'
                    break
                  case 0:
                    caption = 'JavaScript'
                    break
                }
                return (
                  <Grid.Column key={ind}>
                    <svg height={iconSize} width={iconSize} style={{ alignSelf: 'center' }}>
                      {item()}
                    </svg>
                    {title && <Header as='h6' inverted textAlign='center'>{caption}</Header>}
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </Grid>
        }
      </Container>
    },
    {
      name: 'Final Space',
      image: 'https://res.cloudinary.com/dkvkg5pxm/image/upload/v1641401676/fspace_mulyfs.webp', description: 'React application built to consume and display information from a public API about an animated TV show ',
      url: 'https://final-space-info.netlify.app/',
      process: <Container text>
        <Header as='h4' size='medium' inverted>Brief </Header>
        <p>My second project with GA, this was a pair coded React application with multiple components that consumes a public API put together in 2 days</p>
        <Header as='h4' size='medium' inverted>Result</Header>
        <p>
          My first project using React, as well as my first time live pair-coding. We decided to make a showcase for an animated TV show, focusing on characters and episodes. The design utilizes a lot of card elements, and we were able to reuse a lot of components.

          Utilizing React&apos;s state and passing data as props to the aforementioned reusable components with conditional rendering were my big takeaways from this, and implementing a search feature given the time frame, was an added bonus.
        </p>
        <Header as='h5' size='small' inverted>Built using:</Header>
        {
          <Grid centered stackable doubling>
            <Grid.Row stretched textAlign='center' columns={5} verticalAlign='top'>
              {[
                ReactIcon, JavaScriptIcon, HtmlIcon,
                NodeIcon, cssIcon, BulmaIcon,
                GitHubIcon, GitIcon, slackIcon,
                YarnIcon
              ].map((item, ind) => {
                const title = [3, 5, 1, 6, 8].includes(ind) ? true : false
                let caption
                switch (ind) {
                  case 3:
                    caption = 'Node.js'
                    break
                  case 5:
                    caption = 'Bulma'
                    break
                  case 1:
                    caption = 'JavaScript'
                    break
                  case 6:
                    caption = 'GitHub'
                    break
                  case 8:
                    caption = 'Slack'
                    break
                }
                return (
                  <Grid.Column textAlign='center' key={ind}>
                    <svg height={iconSize} width={iconSize} style={{ alignSelf: 'center' }}>
                      {item()}
                    </svg>
                    {title && <Header as='h6' inverted textAlign='center'>{caption}</Header>}
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </Grid>
        }
      </Container>
    },
    {
      name: 'Tokenizer',
      image: 'https://res.cloudinary.com/dkvkg5pxm/image/upload/c_scale,w_800/v1641402185/tokenizer2_c0yvd3.webp',
      description: 'MERN stack reimagining of OpenSea',
      url: 'https://tokenized.herokuapp.com/',
      process: <Container text>
        <Header as='h4' size='medium' inverted>Brief </Header>
        <p>
          My third project, A full-stack MERN (Mongo, Express, React, Node.js) website built over 10 days by a group of 3.
        </p>
        <Header as='h4' size='medium' inverted>Result</Header>
        <p>
          We decided to build a clone of OpenSea/Kraken, so our design cues come from there. I focused on functionality, implementing our shopping cart as well as the data visualization for pricing on each NFT in the &quot;marketplace&quot;.
        </p>
        <Header as='h5' size='small' inverted>Built using:</Header>
        {
          <Grid centered stackable doubling>
            <Grid.Row columns={6} verticalAlign='top' divided>
              {[
                ReactIcon, JavaScriptIcon, MongoIcon,
                expressIcon, GitHubIcon, GitIcon,
                slackIcon, YarnIcon, HerokuIcon,
                NodeIcon, HtmlIcon, cssIcon
              ].map((item, ind) => {
                const title = [4, 1, 3, 6, 9].includes(ind) ? true : false
                let caption
                switch (ind) {
                  case 4:
                    caption = 'GitHub'
                    break
                  case 1:
                    caption = 'JavaScript'
                    break
                  case 3:
                    caption = 'Express'
                    break
                  case 6:
                    caption = 'Slack'
                    break
                  case 9:
                    caption = 'Node.js'
                    break
                }
                return (
                  <Grid.Column verticalAlign='top' key={ind}>
                    <svg height={iconSize} width={iconSize} style={{ alignSelf: 'center' }}>
                      {item()}
                    </svg>
                    {title && <Header as='h6' inverted textAlign='center'>{caption}</Header>}
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </Grid>
        }
      </Container>
    },
    {
      name: 'myCart',
      image: 'https://res.cloudinary.com/dkvkg5pxm/image/upload/c_scale,w_800/v1641401640/myCart_uktpaq.webp',
      description: 'Full stack e-commerce app built with Django and React',
      url: 'https://mycart-dev.herokuapp.com/',
      process: <Container text>
        <Header as='h4' size='medium' inverted>Brief </Header>
        <p>
          Full stack app built solo over 10 days using a Django back end and React front end.
        </p>
        <Header as='h4' size='medium' inverted>Result</Header>
        <p>
          For my last GA project I again focused on e-commerce, taking inspiration from a few delivery grocery apps such as Picnic. The design is mobile-first, and also features a working strip integration.
        </p>
        <Header as='h5' size='small' inverted>Built using:</Header>
        {
          <Grid centered stackable doubling>
            <Grid.Row stretched columns={6} verticalAlign='top'>
              {[
                ReactIcon, DjangoIcon, PythonIcon,
                JavaScriptIcon, BootstrapIcon, PostgresIcon,
                HerokuIcon, GitHubIcon, GitIcon,
                YarnIcon, HtmlIcon, cssIcon, 
                figmaIcon, poetryIcon
              ].map((item, ind) => {
                const title = [3, 7, 12, 13].includes(ind) ? true : false
                let caption
                switch (ind) {
                  case 7:
                    caption = 'GitHub'
                    break
                  case 3:
                    caption = 'JavaScript'
                    break
                  case 12:
                    caption = 'Figma'
                    break
                  case 13:
                    caption = 'Poetry'
                    break
                }
                return (
                  <Grid.Column key={ind}>
                    <svg height={iconSize} width={iconSize} style={{ alignSelf: 'center' }}>
                      {item()}
                    </svg>
                    {title && <Header as='h6' inverted textAlign='center'>{caption}</Header>}
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </Grid>
        }
      </Container>
    }
  ]

  const detailModal = (index) => {
    const project = projects[index]
    const link = project.url
    const content = project.process
    return (
      <Modal
        key={index}
        trigger={<Button color='blue' size={'mini'} index={index}>Click for more information</Button>}
        header={project.name}
        dimmer
        basic
        content={content}
        closeIcon
        actions={[
          { key: 'close', content: 'Close', basic: true, color: 'red' },
          { key: 'visit', content: 'Visit', basic: true, color: 'teal', as: 'a', href: link }
        ]}
      />
    )
  }


  return (
    <Segment id='portfolio' basic>
      <Container>
        <Header textAlign='center' size='large'> My Work </Header>
        <CarouselProvider
          naturalSlideHeight={1}
          naturalSlideWidth={1}
          totalSlides={4}
          visibleSlides={1}
          isIntrinsicHeight
          id='carousel'
          isPlaying
        >
          <Grid>
            <Grid.Column verticalAlign='middle'>
              <Button icon basic as={ButtonBack} color='blue' className='mobile-hide tablet-hide'><Icon name='chevron left' /></Button>
            </Grid.Column>
            <Grid.Column width={14}>
              <div className='wrapper'>
                <Slider className='showcase'>
                  {projects.map((proj, ind) => {
                    return (
                      <Slide
                        ariaLabel={proj.name}
                        index={ind}
                        key={ind}
                      >
                        <Card
                          color='blue'
                          style={{ width: '77%' }}
                          centered
                          className='displayCard'
                        >
                          <Card.Content textAlign='center'>
                            <SImage className='cardImage' bordered>
                              <Image
                                as='a'
                                src={proj.image}
                                className='showImage'
                                alt={`screenshot from ${proj.name}`}
                              />
                            </SImage>
                            <Card.Header>
                              {proj.name}
                            </Card.Header>
                            <Card.Description textAlign='center'>{proj.description}</Card.Description>
                            {detailModal(ind)}
                          </Card.Content>
                        </Card>
                      </Slide>
                    )
                  })}
                </Slider>
              </div>
            </Grid.Column>
            <Grid.Column verticalAlign='middle'>
              <Button
                icon
                basic
                as={ButtonNext}
                color='blue'
                className=' mobile-hide tablet-hide'>
                <Icon name='chevron right' />
              </Button>
            </Grid.Column>
          </Grid>
          <Container textAlign='center'>
            <Button.Group size='tiny'>
              {projects.map((_item, ind) => (
                <Button as={Dot} key={ind} slide={ind} color='blue' icon='circle' compact />
              ))}
            </Button.Group>
          </Container>
        </CarouselProvider>
      </Container>
    </Segment>
  )
}

export default Portfolio