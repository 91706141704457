import React, { useEffect } from 'react'
// /*eslint-disable no-unused-vars */

import ResponsiveHeader from 'dna-responsive-nav'
import { useLocation, Link } from 'react-router-dom'
import 'dna-responsive-nav/dist/dna-rn.css'

const Navbar = () => {

  const location = useLocation()

  useEffect(() => {
    const url = window.location.href.split('/')
    const target = url[url.length - 1].toLowerCase()
    const element = document.getElementById(target)
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [])

  const links = (
    <ul>
      <li key='home'>
        <Link className={location.pathname === '/' ? 'is-active' : ''}
          to='/'
          onClick={() => {
            const home = document.getElementById('home')
            const yOffset = 70
            const y = home.getBoundingClientRect().top + window.pageYOffset - yOffset
            home && window.scrollTo({ top: y, behavior: 'smooth' })
          }}
        >
          Home
        </Link>
      </li>
      <li key='about'>
        <Link
          className={location.pathname === '/about' ? 'is-active' : ''}
          to='/about'
          onClick={() => {
            const about = document.getElementById('about')
            about && about.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }}
        >
          About
        </Link>
      </li>
      <li key='portfolio'>
        <Link
          className={location.pathname === '/portfolio' ? 'is-active' : ''}
          to='/portfolio'
          onClick={() => {
            const portfolio = document.getElementById('carousel')
            const yOffset = 70
            const y = portfolio.getBoundingClientRect().top + window.pageYOffset - yOffset
            portfolio && window.scrollTo({ top: y, behavior: 'smooth' })
          }}
        >
          Portfolio
        </Link>
      </li>
      <li key='contact'>
        <Link
          className={location.pathname === '/contact' ? 'is-active' : ''}
          to='/contact'
          onClick={() => {
            const contactMe = document.getElementById('contact')
            contactMe && contactMe.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }}
        >
          Contact
        </Link>
      </li>
    </ul>
  )

  useEffect(() => {

  }, [location.pathname])

  return (


    <ResponsiveHeader
      siteName='Ricardo'
      links={links}
    />
  )
}

export default Navbar