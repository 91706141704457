import React from 'react'
import { Button, Grid, Icon, Segment } from 'semantic-ui-react'
import { gitHubIconFooter, linkedInIconFooter } from './helpers/Icons'

const Footer = () => {


  return (
    <Segment
      inverted
      id='footer'
    >
      <Grid
        centered
        doubling
      >
        <Grid.Row textAlign='center'>
          <Grid.Column mobile={16} tablet={8} computer={2} textAlign='center'>
            <Button animated='fade' color='black' inverted as='a' href='https://github.com/rjriverac'>
              <Button.Content visible >
                {gitHubIconFooter({ height: '20px', width: '20px' })}
              </Button.Content>
              <Button.Content hidden >
                {/* {gitHubIconFooter({ height: '14px', width: '14px', fill: '#181616' })} */}
                <Icon name='sign-out'/>
              </Button.Content>
            </Button>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={2} textAlign='center'>
            <Button animated='fade' color ='black' inverted as='a' href='https://www.linkedin.com/in/rjriverac/'>
              <Button.Content visible>
                {linkedInIconFooter({ height: '20px', width: '20px' })}
              </Button.Content>
              <Button.Content hidden>
                {/* {linkedInIconFooter({ height: '14px', width: '14px' })} */}
                <Icon name='sign-out'/>
              </Button.Content>
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default Footer