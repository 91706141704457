import React from 'react'
import { Container, Header, Image, Segment } from 'semantic-ui-react'
import 'animate.css'
import picture from '../assets/squarePortrait.jpg'



const Home = () => {

  return (
    <div className='hero gradient-background'>
      <Segment id='home' inverted basic>
        <Container textAlign='center' fluid>
          <Header as='h1' inverted className='sectionHeading animate__animated animate__fadeIn animate__slow'>
            Hi, I&apos;m Ricardo
          </Header>
          <Image
            as='a'
            src={picture}
            centered
            verticalAlign='middle'
            circular
            size='medium'
            className='animate__animated animate__delay-2s animate__fadeIn'
            alt='my picture'
          />
          <br/>
          <p className='animate__animated animate__delay-2s animate__fadeIn'>Junior Full Stack Developer</p>
        </Container>
      </Segment>
    </div >
  )
}

export default Home