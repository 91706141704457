import React, { useState } from 'react'
import { Button, Container, Form, Header, Message, Segment } from 'semantic-ui-react'
import emailjs from 'emailjs-com'


const Contact = () => {

  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: '',
    sent: false
  })

  const [submitError, setSubmitError] = useState(false)

  const handleChange = (e) => {
    const newData = { ...contact, [e.target.name]: e.target.value }
    setContact(newData)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (Object.values(contact).slice(0, 2).every(field => !!field)) {
      const serviceID = process.env.REACT_APP_SERVICEID
      const templateID = process.env.REACT_APP_TEMPLATEID
      const userID = process.env.REACT_APP_USER_ID
      const { name, email, message } = contact
      const templateParams = {
        name,
        email,
        message
      }

      emailjs.send(serviceID, templateID, templateParams, userID)
        .then(response => console.log(response))
        .then(error => console.log(error))

      setTimeout(() => {
        setContact({
          name: '',
          email: '',
          message: '',
          sent: true
        })
      }, 100)
    } else {
      setSubmitError(true)
    }
  }

  if (contact.sent) {
    return (
      <Container textAlign='center'>
        <Segment style={{ padding: ' 5vh 0 18vh 0' }}>
          <Message icon='inbox' header='Thank you!' content={'I\'ll be in touch soon!'} />
        </Segment>
      </Container>
    )
  }

  return (
    <Segment style={{ padding: '5vh 0 20vh 0', margin: '0 auto' }} secondary>
      <Container id='contact' textAlign='center' >
        <Header as='h3'>If you&apos;d like to get in touch about a project or oppportunity please fill in the form below and I&apos;ll get back to you as soon as possible.</Header>
        <Form
          onSubmit={handleSubmit}
        >
          <Form.Group widths={2}>
            <Form.Input
              label='Please enter your name'
              placeholder='John Smith'
              type='text'
              onChange={handleChange}
              name='name'
              value={contact.name}
              required
            />
            <Form.Input
              label='Enter your email address'
              placeholder='example@example.com'
              type='email'
              onChange={handleChange}
              name='email'
              value={contact.email}
              required
            />
          </Form.Group>
          <Form.TextArea
            rows={2}
            label='Add your message'
            placeholder='Hi!'
            onChange={handleChange}
            name='message'
            type='text'
            value={contact.message}
            required
          />
          <Button type='submit'>Send!</Button>
          {submitError &&
            <Message header='Something went wrong' content='Please try again' />
          }
          <br />
        </Form>
      </Container>
    </Segment>
  )
}

export default Contact