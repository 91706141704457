import React from 'react'
import { Accordion, Container, Divider, Grid, Header, Segment } from 'semantic-ui-react'
import { BootstrapIcon, BulmaIcon, cssIcon, DjangoIcon, expressIcon, figmaIcon, GitHubIcon, GitIcon, HtmlIcon, JavaScriptIcon, MongoIcon, NodeIcon, poetryIcon, PostgresIcon, PythonIcon, ReactIcon, slackIcon } from './helpers/Icons'

const About = () => {

  const iconSize = 60

  const panels = [
    {
      key: 'skills',
      title: 'Skills/technologies',
      content: {
        content: (
          <Grid centered stackable doubling>
            <Grid.Row columns={7} textAlign='center'>
              {
                [
                  ReactIcon, JavaScriptIcon, DjangoIcon,
                  PythonIcon, MongoIcon, expressIcon,
                  PostgresIcon, NodeIcon, cssIcon,
                  BulmaIcon, BootstrapIcon, HtmlIcon,
                  GitIcon, GitHubIcon, figmaIcon,
                  slackIcon,poetryIcon]
                  .map((item, ind) => {
                    const title = [9,13,1,14,15,5,7,16].includes(ind) ? true : false
                    let caption
                    switch (ind) {
                      case 7:
                        caption = 'Node.js'
                        break
                      case 9:
                        caption = 'Bulma'
                        break
                      case 13:
                        caption = 'GitHub'
                        break
                      case 1:
                        caption = 'JavaScript'
                        break
                      case 14:
                        caption = 'Figma'
                        break
                      case 15:
                        caption = 'Slack'
                        break
                      case 5:
                        caption = 'Express'
                        break
                      case 16:
                        caption = 'Poetry'
                        break
                    }
                    return (
                      <Grid.Column textAlign='center' key={ind}>
                        <svg height={iconSize} width={iconSize} style={{ alignSelf: 'center' }}>
                          {item()}
                        </svg>
                        {title && <Header as='h6' inverted textAlign='center'>{caption}</Header>}
                      </Grid.Column>
                    )
                  })
              }
            </Grid.Row>
          </Grid>
        )
      }
    },
    {
      key: 'interests',
      title: 'Interests',
      content: {
        content: (
          <div>

            <Header inverted>Mixology</Header>
            <hr />
            <p>I enjoy trying and making cocktails (my current favorite is a 2 parts gin, 1 part lemon juice, 1 part passionfruit syrup, and hibiscus bitters).
            </p>
            <Header inverted content='Reading' />
            <hr />
            <p>I tend to prefer sci-fi/fantasy series, and recently re-read the Dune series in anticipation of the movie release. Next up is The Wheel of Time.
            </p>
            <Header inverted content='Travel' />
            <hr />
            <p>
              I want to visit the Aegean coast of Turkey and Petra once travel becomes a bit less uncertain.
            </p>
          </div>
        )
      }
    }
  ]

  return (
    <div id='about' >
      <Segment inverted basic className='bio'>
        <Container text textAlign='center' style={{ padding: '80px 0 0 0' }}>
          <Header as='h1' size={'huge'} className='sectionHeading' inverted>about me</Header>
          <p>
            I have always been a logical thinker and enjoyed solving problems, and discovered during my career in financial services a curiosity for coding. While creating excel macros for my team I also learned some scripting and began tinkering in my free time.
            <br /><br />I decided to enroll in a Software Engineering Immersive course in order to jump-start my technical knowledge and provide a base from which to launch my career into tech. I am looking for my next step to leverage both my experience in finance and knowledge in tech in order to work in a vibrant, innovative software development team.
          </p>
        </Container>
        <br />
        <br />
        <Divider />
        <Container textAlign='justified'>
          <Accordion defaultActiveIndex={0} inverted panels={panels} />
        </Container>
      </Segment>
    </div>
  )
}

export default About